import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormLabel, FormControlLabel, OutlinedInput, InputLabel, Grid, RadioGroup, Radio, Checkbox } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import DateFnsUtils from '@date-io/date-fns'
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
}))

const StepSix = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    pricing,
    ICDiscountPercent,
    ICDiscountPerItem,
    debit,
    debitDiscount,
    debitPerItem,
    ICEbtfnsNumber,
    ICEbtfnsPerItem,
    ICEbtfnsCachBackPerItem,
    TRQualified,
    TRQualifiedPerItem,
    TRMidQualified,
    TRMidQualifiedPerItem,
    TRNonQualified,
    TRNonQualifiedPerItem,
    TRCheckCard,
    TRCheckCardPerItem,
    authorizationFee,
    chargeBackFee,
    retrievalFee,
    monthlyMinimum,
    batchFee,
    tinReportingAnnual,
    achReturnFee,
    setupFee,
    monthlyServiceFee,
    servicePack,
    pci,
    nonPci,
    earlyTerminationFee,
    wirelessFee,
    wirelessFeePerItem,
    ddaChangeFee,
    cashDiscount,
    readOnlyFields,
    /* date, */
  } = useSelector(state => state.Form)
  
  const typeOfPricing = e => {
    if (e.target.value === 'Interchange') dispatch({ type: 'INTERCHANGE'})
    if (e.target.value === 'Tiered') dispatch({ type: 'TIERED'})
  }

  return (
    <>
      <h2>Pricing</h2>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.formControl}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Type of pricing</FormLabel>
              <RadioGroup row aria-label='pricing' name='pricing' value={pricing} onChange={e => {typeOfPricing(e)}}>
                <FormControlLabel disabled={readOnlyFields} value='Interchange' control={<Radio color='primary' required />} label='Interchange' />
                <FormControlLabel disabled={readOnlyFields} value='Tiered' control={<Radio color='primary' required />} label='Tiered' />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
        {pricing === 'Interchange' && (
          <>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='ic-discount-percent'>Discount %</InputLabel>
                  <OutlinedInput
                    labelWidth={100}
                    id='ic-discount-percent'
                    value={ICDiscountPercent}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICDiscountPercent', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='ic-discount-per-item'>Per Item</InputLabel>
                  <OutlinedInput
                    labelWidth={85}
                    id='ic-discount-per-item'
                    value={ICDiscountPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICDiscountPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControlLabel
                  disabled={readOnlyFields}
                  control={<Checkbox disabled={readOnlyFields} checked={debit} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'debit', value: e.target.checked } })} name='debit' color='primary' />}
                  label='Debit'
                />
              </div>
            </Grid>
            {debit && (
              <>
                <Grid item xs={12} sm={6}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel htmlFor='debit-discount'>Discount</InputLabel>
                      <OutlinedInput readOnly={readOnlyFields} labelWidth={85} id='debit-discount' value={debitDiscount} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'debitDiscount', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel htmlFor='debit-per-item'>Per item</InputLabel>
                      <OutlinedInput readOnly={readOnlyFields} labelWidth={80} id='debit-per-item' value={debitPerItem} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'debitPerItem', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='ic-ebt-fns-number'>EBT/FNS #</InputLabel>
                  <OutlinedInput
                    labelWidth={100}
                    id='ic-ebt-fns-number'
                    value={ICEbtfnsNumber}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICEbtfnsNumber', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' >
                  <InputLabel htmlFor='ic-ebt-fns-per-item'>Per item</InputLabel>
                  <OutlinedInput
                    labelWidth={80}
                    id='ic-ebt-fns-per-item'
                    value={ICEbtfnsPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICEbtfnsPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='ic-ebt-fns-cash-back-per-item'>Cash Benefits Only Per Item</InputLabel>
                  <OutlinedInput
                    labelWidth={215}
                    id='ic-ebt-fns-cash-back-per-item'
                    value={ICEbtfnsCachBackPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICEbtfnsCachBackPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
          </>
        )}
        {pricing === 'Tiered' && (
          <>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Discount Type</FormLabel>
                  <RadioGroup row aria-label='pricing' name='pricing' value={cashDiscount} onChange={e => dispatch({ type: e.target.value })}>
                    <FormControlLabel disabled={readOnlyFields} value='CD_4' control={<Radio color='primary' required />} label='Cash Discount 4%' />
                    <FormControlLabel disabled={readOnlyFields} value='CD_3_5' control={<Radio color='primary' required />} label='Cash Discount 3.5%' />
                    <FormControlLabel disabled={readOnlyFields} value='CD_3' control={<Radio color='primary' required />} label='Cash Discount 3%' />
                    <FormControlLabel disabled={readOnlyFields} value='CD_CUSTOM' control={<Radio color='primary' required />} label='Custom' />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.formControl}>
                <FormControlLabel
                  control={<Checkbox checked={debit} readOnly={readOnlyFields} onChange={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'debit', value: e.target.checked } })} name='debit' color='primary' />}
                  label='Debit'
                />
              </div>
            </Grid>
            {debit && (
              <>
                <Grid item xs={12} sm={6}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel htmlFor='debit-discount'>Discount</InputLabel>
                      <OutlinedInput readOnly={readOnlyFields} labelWidth={85} id='debit-discount' value={debitDiscount} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'debitDiscount', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.formControl}>
                    <FormControl fullWidth variant='outlined' required>
                      <InputLabel htmlFor='debit-per-item'>Per item</InputLabel>
                      <OutlinedInput readOnly={readOnlyFields} labelWidth={80} id='debit-per-item' value={debitPerItem} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'debitPerItem', value: e.target.value } })} />
                    </FormControl>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-qualified'>Qualified</InputLabel>
                  <OutlinedInput readOnly={readOnlyFields} labelWidth={85} id='tr-qualified' value={TRQualified} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRQualified', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-qualified-per-item'>Per item</InputLabel>
                  <OutlinedInput
                    labelWidth={80}
                    id='tr-qualified-per-item'
                    value={TRQualifiedPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRQualifiedPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-mid-qualified'>MID-Qualified</InputLabel>
                  <OutlinedInput
                    labelWidth={120}
                    id='tr-mid-qualified'
                    value={TRMidQualified}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRMidQualified', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-mid-qualified-per-item'>Per item</InputLabel>
                  <OutlinedInput
                    labelWidth={80}
                    id='tr-mid-qualified-per-item'
                    value={TRMidQualifiedPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRMidQualifiedPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-non-qualified'>NON-Qualified</InputLabel>
                  <OutlinedInput
                    labelWidth={120}
                    id='tr-non-qualified'
                    value={TRNonQualified}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRNonQualified', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-non-qualified-per-item'>Per item</InputLabel>
                  <OutlinedInput
                    labelWidth={80}
                    id='tr-non-qualified-per-item'
                    value={TRNonQualifiedPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRNonQualifiedPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-check-card'>Check card</InputLabel>
                  <OutlinedInput readOnly={readOnlyFields} labelWidth={95} id='tr-check-card' value={TRCheckCard} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRCheckCard', value: e.target.value } })} />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined' required>
                  <InputLabel htmlFor='tr-check-card-per-item'>Per item</InputLabel>
                  <OutlinedInput
                    labelWidth={80}
                    id='tr-check-card-per-item'
                    value={TRCheckCardPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'TRCheckCardPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='ic-ebt-fns-number'>EBT/FNS #</InputLabel>
                  <OutlinedInput
                    labelWidth={100}
                    id='ic-ebt-fns-number'
                    value={ICEbtfnsNumber}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICEbtfnsNumber', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='ic-ebt-fns-per-item'>Per item</InputLabel>
                  <OutlinedInput
                    labelWidth={80}
                    id='ic-ebt-fns-per-item'
                    value={ICEbtfnsPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICEbtfnsPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.formControl}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='ic-ebt-fns-cash-back-per-item'>Cash Benefits Only Per Item</InputLabel>
                  <OutlinedInput
                    labelWidth={215}
                    id='ic-ebt-fns-cash-back-per-item'
                    value={ICEbtfnsCachBackPerItem}
                    onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ICEbtfnsCachBackPerItem', value: e.target.value } })}
                    readOnly={readOnlyFields}
                  />
                </FormControl>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='authorization-fee'>Authorization fee</InputLabel>
              <OutlinedInput
                labelWidth={140}
                id='authorization-fee'
                value={authorizationFee}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'authorizationFee', value: e.target.value } })}
                readOnly={readOnlyFields}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='charge-back-fee'>Chargeback fee</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={135} id='charge-back-fee' value={chargeBackFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'chargeBackFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='retrieval-fee'>Retrieval fee</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={110} id='retrieval-fee' value={retrievalFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'retrievalFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='monthly-minimum'>Monthly minimum</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={150} id='monthly-minimum' value={monthlyMinimum} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'monthlyMinimum', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='batch-fee'>Batch fee</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={85} id='batch-fee' value={batchFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'batchFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='tin-reporting-annual'>Reg Product/TIN Match</InputLabel>
              <OutlinedInput
                labelWidth={190}
                id='tin-reporting-annual'
                value={tinReportingAnnual}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'tinReportingAnnual', value: e.target.value } })}
                readOnly={readOnlyFields}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='ach-return-fee'>ACH return fee</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={120} id='ach-return-fee' value={achReturnFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'achReturnFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='setup-fee'>Setup fee</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={85} id='setup-fee' value={setupFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'setupFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='monthly-service-fee'>Monthly service fee</InputLabel>
              <OutlinedInput
                labelWidth={160}
                id='monthly-service-fee'
                value={monthlyServiceFee}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'monthlyServiceFee', value: e.target.value } })}
                readOnly={readOnlyFields}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='service-pack'>Service pack</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={110} id='service-pack' value={servicePack} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'servicePack', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='pci'>PCI</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={40} id='pci' value={pci} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'pci', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='non-pci'>NON-PCI</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={80} id='non-pci' value={nonPci} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'nonPci', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' >
              <InputLabel htmlFor='early-termination-fee'>Early termination fee</InputLabel>
              <OutlinedInput
                labelWidth={160}
                id='early-termination-fee'
                value={earlyTerminationFee}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'earlyTerminationFee', value: e.target.value } })}
                readOnly={readOnlyFields}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='wireless-fee'>Wireless fee</InputLabel>
              <OutlinedInput readOnly={readOnlyFields} labelWidth={105} id='wireless-fee' value={wirelessFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'wirelessFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel htmlFor='wireless-fee-per-item'>Wireless fee per item</InputLabel>
              <OutlinedInput
                readOnly={readOnlyFields}
                labelWidth={170}
                id='wireless-fee-per-item'
                value={wirelessFeePerItem}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'wirelessFeePerItem', value: e.target.value } })}
              />
            </FormControl>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='dda-cange-fee'>DDA Change fee</InputLabel>
              <OutlinedInput labelWidth={140} id='dda-cange-fee' value={ddaChangeFee} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ddaChangeFee', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid> */}

        {/* <Grid item xs={12} sm={6}>
          <div className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl fullWidth variant='outlined' required>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  inputVariant="outlined"
                  variant='inline'
                  format='MM/dd/yyyy'
                  id='date-picker-inline'
                  label='Date'
                  value={date}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'date', value: date } })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </div>
        </Grid> */}
      </Grid>
    </>
  )
}

export default StepSix
