import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, OutlinedInput, InputLabel, Grid, FormHelperText } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
  helper: {
    position: 'absolute',
    bottom: -20,
    left: 0
  }
}))

const StepFour = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { ownerName, ownerDob, ownerSsn, ownerTitle, ownerCity, ownerAddress, ownerState, ownerZip, ownerPhone, ownerOwnedPercent } = useSelector(state => state.Form)

  return (
    <>
      <h2>Owner And Officer Information</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-name'>Owner Name</InputLabel>
              <OutlinedInput labelWidth={105} id='owner-name' value={ownerName} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerName', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-title'>Owner Title</InputLabel>
              <OutlinedInput labelWidth={95} id='owner-title' value={ownerTitle} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerTitle', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <div className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl fullWidth variant='outlined' required>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  inputVariant="outlined"
                  required
                  variant='inline'
                  format='MM/dd/yyyy'
                  id='date-picker-inline'
                  label='Date of Birth'
                  value={ownerDob}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={date => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerDob', value: date } })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-ssn'>Owner Ssn</InputLabel>
              <OutlinedInput type="text" labelWidth={95} id='owner-ssn' value={ownerSsn} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerSsn', value: e.target.value } })} />
              <FormHelperText className={classes.helper}>No spaces</FormHelperText>
            </FormControl>
          </div>
        </Grid>


        <Grid item xs={12} sm={6} md={8}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-address'>Address</InputLabel>
              <OutlinedInput labelWidth={75} id='owner-address' value={ownerAddress} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerAddress', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-city'>City</InputLabel>
              <OutlinedInput labelWidth={45} id='owner-city' value={ownerCity} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerCity', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-state'>State</InputLabel>
              <OutlinedInput labelWidth={55} id='owner-state' value={ownerState} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerState', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-zip'>ZIP</InputLabel>
              <OutlinedInput inputProps={{ 'pattern': '[0-9]{5}', 'title': 'Enter valid zip code' }} labelWidth={45} id='owner-zip' value={ownerZip} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerZip', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={4}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-phone'>Phone</InputLabel>
              <OutlinedInput type='tel' inputProps={{ 'pattern': '^[0-9]{10,11}', 'title': 'Please input valid number (10 or 11 digits)' }} labelWidth={65} id='owner-phone' value={ownerPhone} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerPhone', value: e.target.value } })} />
              <FormHelperText className={classes.helper}>No spaces</FormHelperText>
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='owner-owned-percent'>% Owned</InputLabel>
              <OutlinedInput
                labelWidth={85}
                id='owner-owned-percent'
                value={ownerOwnedPercent}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'ownerOwnedPercent', value: e.target.value } })}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default StepFour
