const DefaultValidationState = {
  step0: false,
  step1: false,
  step2: false,
  step3: false,
  step4: false,
  step5: false,
  formSent: false,
  loading: false,
  errorText: '',
  error: false,
  success: false,
  successText: '',
  activeStep: 0,
  logged: false,
  password: ''
}



const FormValidationReducer = (state = DefaultValidationState, action) => { 
  switch (action.type) {
    case 'UPDATE_VALIDITY':
      const tempObject = {}
      tempObject[action.payload.label] = action.payload.value
      return {
        ...state,
        ...tempObject,
      }
    default:
      return state
  }
}

export default FormValidationReducer
