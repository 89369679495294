import React, { createRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Stepper, Step, StepLabel, CircularProgress, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography'
import StepOne from '../steps/StepOne'
import StepTwo from '../steps/StepTwo'
import StepThree from '../steps/StepThree'
import StepFour from '../steps/StepFour'
import StepFive from '../steps/StepFive'
import StepSix from '../steps/StepSix'
import StepSeven from '../steps/StepSeven'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import copy from 'copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '1800px',
    margin: '150px auto 100px',
    boxShadow: '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    position: 'relative'
  },
  header: {
    boxShadow: '0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textAlign: 'left',
  },
  formFooter: {
    padding: theme.spacing(3),
    paddingTop: '20px',
    textAlign: 'left',
    paddingLeft: 'unset'
  },
  stepButton: {
    cursor: 'pointer'
  },
  marginRight: {
    marginRight: theme.spacing(1),
  }
}))

function getSteps() {
  return ['Business Information', 'W9 Information', 'Processing Data', 'Owner and Officer Information', 'Banking Information', 'Pricing', 'Finish']
}

const regEmail = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <StepOne />
    case 1:
      return <StepTwo />
    case 2:
      return <StepThree />
    case 3:
      return <StepFour />
    case 4:
      return <StepFive />
    case 5:
      return <StepSix />
    case 6:
      return <StepSeven />
    default:
      return 'Unknown step'
  }
}

const sendForm = async (data) => {
  const formData = new FormData();

  for (const name in data) {
    if (name === "files") {
      data[name].map((file) => formData.append(name + "[]", file));
    } else {
      formData.append(name, data[name]);
    }
  }

  const response = await fetch('/api/send-data', {
    method: 'POST',
    body: formData
  })

  const responseJson = await response.json()
  return await responseJson.message
}

const Form = () => {
  const classes = useStyles()
  //  const dispatch = useDispatch()
  // const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const dispatch = useDispatch()
  let history = useHistory();
  const { /* step0, step1, step2, step3, step4, step5, */ loading, error, errorText, success, successText, activeStep } = useSelector(state => state.Validation)
  const FormFileds = useSelector(state => state.Form)
  const formRef = createRef()
  const getFormResult = async (data) => {
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'loading', value: true } })
    const result = await sendForm(data)
    if (await result === 'success') {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'formSent', value: true } })
      history.push("/thank-you");
    } else {
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'formSent', value: false } })
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: true } })
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'errorText', value: 'Error while sending your data' } })
    }

    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'loading', value: false } })
    return await result
  }

  const handleNext = (e) => {
    e.preventDefault();
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'step' + activeStep, value: formRef.current.reportValidity() } })

    if (activeStep === 0 && formRef.current.reportValidity()) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1)
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
    if (activeStep === 1 && formRef.current.reportValidity()) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1)
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
    if (activeStep === 2 && formRef.current.reportValidity()) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1)
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
    if (activeStep === 3 && formRef.current.reportValidity()) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1)
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
    if (activeStep === 4 && formRef.current.reportValidity()) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1)
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
    if (activeStep === 5 && formRef.current.reportValidity()) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1)
      dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep + 1 } })
    }
    if (activeStep === 6 && formRef.current.reportValidity()) {
      if (FormFileds.emailAddress.length && regEmail.test(FormFileds.emailAddress)) {
        if (FormFileds.eSignURL.length) {
          getFormResult(FormFileds)
        } else {
          dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'errorText', value: 'Please Complete E-Sign' } })
          dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: true } })
        }
      } else {
        dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'errorText', value: 'Enter Valid Email On First Step' } })
        dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: true } })
      }
      //  setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    // setActiveStep(prevActiveStep => prevActiveStep - 1)
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: activeStep - 1 } })
  }

  const handleReset = () => {
    // setActiveStep(0)
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: 0 } })
  }

  const copyLink = () => {
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'successText', value: 'Link copied' } })
    dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: true } })
  }

  const requestCopyLink = () => {
    const response = fetch('/api/copy-link', {
      method: 'POST',
      body: JSON.stringify({ fields: FormFileds }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    response.then(res => res.json()).then(data => {
      if (data.message === 'success') {
        copy(data.link)
        copyLink()
      } else {
        dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'errorText', value: 'Error while copying link' } })
        dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: true } })
      }
    })
  }

  return (
    <div className={classes.root}>
      {loading && (
        <div className="prosvit-loader">
          <CircularProgress />
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        autoHideDuration={3000}
        onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: false } })}
      >
        <Alert variant="filled" onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: false } })} severity="error">
          {errorText}
        </Alert>
        {/* <Alert variant="filled" onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: false } })} severity="success">
          {errorText}
        </Alert> */}
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={success}
        autoHideDuration={3000}
        onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: false } })}
      >
        <Alert variant="filled" onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'success', value: false } })} severity="success">
          {successText}
        </Alert>
        {/* <Alert variant="filled" onClose={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'error', value: false } })} severity="success">
          {errorText}
        </Alert> */}
      </Snackbar>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.header}>
        {steps.map((label, index) => (
          <Step key={label} className='step-button' onClick={() => dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'activeStep', value: index } })}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className='MuiStep-content'>
        {activeStep === steps.length ? (
          <div className='MuiStep-content-item'>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <>
            <form ref={formRef} className={classes.form} onSubmit={handleNext}>
              {getStepContent(activeStep)}
              <div className={classes.formFooter}>
                <Button className={classes.marginRight} variant='contained' disabled={activeStep === steps.length - 1 && !FormFileds.readConsent} color='primary' type="submit" >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                <div className="copy-area">
                  <Button className={classes.marginRight} color="primary" type="button" onClick={requestCopyLink}>
                    Copy Link
                  </Button>
                </div>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
              </div>
            </form>

          </>
        )}
      </div>
    </div>
  )
}

export default Form
