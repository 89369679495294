import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, OutlinedInput, InputLabel, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative'
  },
}))

const StepThree = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { monthlyVolumeAllCards, totalVolume, discoverVolume, amexVolume, averageTicket, highTicket, percentSwiped, percentKeyed, productsServices, } = useSelector(state => state.Form)

  return (
    <>
      <h2>Processing Data</h2>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='monthly-volume-all-cards'>Total Annual Volume (cash, checks, cards)</InputLabel>
              <OutlinedInput
                labelWidth={330}
                id='monthly-volume-all-cards'
                value={monthlyVolumeAllCards}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'monthlyVolumeAllCards', value: e.target.value } })}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='total-volume'>Visa/MC Annual Volume</InputLabel>
              <OutlinedInput
                labelWidth={200}
                id='total-volume'
                value={totalVolume}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'totalVolume', value: e.target.value } })}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='discover-volume'>Discover Annual Volume</InputLabel>
              <OutlinedInput
                labelWidth={200}
                id='discover-volume'
                value={discoverVolume}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'discoverVolume', value: e.target.value } })}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='amex-volume'>Amex Annual Volume</InputLabel>
              <OutlinedInput
                labelWidth={180}
                id='amex-volume'
                value={amexVolume}
                onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'amexVolume', value: e.target.value } })}
              />
            </FormControl>
          </div>
        </Grid>
        
        
        
        
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='average-ticket'>Average Ticket</InputLabel>
              <OutlinedInput labelWidth={125} id='average-ticket' value={averageTicket} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'averageTicket', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='high-ticket'>High Ticket</InputLabel>
              <OutlinedInput labelWidth={105} id='high-ticket' value={highTicket} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'highTicket', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='percent-swiped'>% Swiped</InputLabel>
              <OutlinedInput type="number" inputProps={{ min: "0", max: "100", step: "1" }} labelWidth={85} id='percent-swiped' value={percentSwiped} onInput={e => dispatch({ type: 'SWIPED', payload:  e.target.value  })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='percent-keyed'>% Keyed</InputLabel>
              <OutlinedInput type="number" inputProps={{ min: "0", max: "100", step: "1" }} labelWidth={85} id='percent-keyed' value={percentKeyed} onInput={e => dispatch({ type: 'KEYED', payload: e.target.value })} />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} >
          <div className={classes.formControl}>
            <FormControl fullWidth variant='outlined' required>
              <InputLabel htmlFor='products-services'>Products/Services</InputLabel>
              <OutlinedInput labelWidth={150} id='products-services' value={productsServices} onInput={e => dispatch({ type: 'UPDATE_FORM_ITEM', payload: { label: 'productsServices', value: e.target.value } })} />
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default StepThree
