import React from 'react'
import { Paper, Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
    maxWidth: '850px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: '30px 20px'
  }
}));

const ThankYou = () => {
  const classes = useStyles()
  const { emailAddress } = useSelector(state => state.Form)
  const { formSent } = useSelector(state => state.Validation)
  const dispatch = useDispatch()
  let history = useHistory();
  
  const startForm = () => {
    dispatch({ type: 'CLEAR_FORM' })
    history.push('/')
  }
  
  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        {formSent && (
          <>
            <h1>Thank You!</h1>
            <h2>Check your email {emailAddress} </h2>
            <Button variant="contained" color="primary" onClick={startForm}>
              Fill Form again
            </Button>
          </>
        )}
        {!formSent && (
          <>
            <h1>Form submission not copleted</h1>
            <Button variant="contained" color="primary" onClick={() => history.push('/')}>
              Complete form
            </Button>
          </>
        )}
      </Paper>
    </div>
  )
}

export default ThankYou
