import React, { useEffect } from 'react'
import './App.css';
import { Switch, Route, Redirect, NavLink, useLocation, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
// import Button from '@material-ui/core/Button'
import Form from './containers/Form'
import ThankYou from './containers/ThankYou'
import Admin from './containers/Admin'
import { useDispatch, /* useSelector */ } from 'react-redux'
const queryString = require('query-string')

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Roboto'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textDecoration: 'none',
    color: 'inherit'
  },
}))

function App() {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const location = useLocation()
  // const history = useHistory()
  // const { logged } = useSelector(state => state.Validation)
  // const pathName = location.pathname

  useEffect(() => {
    if (document.location.search) {
      dispatch({ type: 'LOAD_FROM_URL', payload: queryString.parse(document.location.search, { parseBooleans: true, parseNumbers: false }) })
      window.history.replaceState({}, document.title, "/");
    }
  }, [])
  
  return (
    <div className="App">
      <AppBar>
        <Toolbar>
          <Typography variant='h6' className={classes.title} component={NavLink} to={'/'}>
            <img src="/logo300X100.png" alt="logo" />
          </Typography>
          {/* {(!logged) && (
            <Button color="inherit" component={NavLink} to={'/admin'}>
              Admin
            </Button>
          )}
          {logged && (
            <Button color="inherit" onClick={() => {
              dispatch({ type: 'UPDATE_VALIDITY', payload: { label: 'logged', value: false } })
              history.push("/");
            }}>
              Log out
            </Button>
          )} */}
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path={'/'} exact component={Form} />
        <Route path={'/thank-you/'} exact component={ThankYou} />
        <Route path={'/admin/'} exact component={Admin} />
        <Redirect to={'/'} />
      </Switch>
    </div>
  );
}

export default App;

